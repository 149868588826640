var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pa-3 "},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","height":"410px","dense":"","single-select":"","items":_vm.devislist,"item-key":"id","headers":_vm.selection,"loading":_vm.Loading,"search":_vm.search,"loading-text":"Chargement en cours ... Merci de patienter"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.headers),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-action',[_c('v-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1),_c('v-toolbar-title',[_vm._v("Liste des Devis ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-6",attrs:{"hide-details":"","single-line":"","solo-inverted":"","clearable":"","dense":"","flat":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('div',{attrs:{"id":"app"}})],[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"indigo","disabled":!_vm.isDisable},on:{"click":_vm.devisnew}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],[_c('confirmdialog',{ref:"confirm"})]],2),[_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])]]},proxy:true},{key:"item.produit",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'devis', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.produit)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status_id == 'Brouillon')?_c('v-icon',{staticClass:"mr-2 ",attrs:{"small":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }