<template>
  <div>
    <div class="pa-3 ">
      <v-data-table
        fixed-header
        class="elevation-1"
        height="410px"
        dense
        single-select
        :items="devislist"
        item-key="id"
        :headers="selection"
        :loading="Loading"
        :search="search"
        loading-text="Chargement en cours ... Merci de patienter"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-menu bottom left :close-on-content-click="closeOnContentClick">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  small
                  color="indigo"
                  class="mx-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in headers" :key="i">
                  <v-list-item-action>
                    <v-checkbox v-model="item.selected"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-toolbar-title>Liste des Devis </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            >
            </v-text-field>

            <template>
              <div id="app">
                <!--downloadexcel
                  class="btn"
                  :fetch="Alldevis"
                  name="Devis.xls"
                  worksheet="Devis"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                >
                  <v-btn
                    fab
                    dark
                    small
                    color="indigo"
                    class="ma-2"
                    :disabled="total == 0"
                  >
                    <v-icon dark>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </downloadexcel-->
              </div>
            </template>
            <template>
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click="devisnew"
                :disabled="!isDisable"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>

            <template>
              <confirmdialog ref="confirm" />
            </template>
          </v-toolbar>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="snackbar_timeout"
              top
              :color="snackbar_color"
            >
              {{ snackbar_text }}
            </v-snackbar>
          </template>
        </template>
        <template v-slot:[`item.produit`]="{ item }">
          <router-link :to="{ name: 'devis', params: { id: item.id } }">
            {{ item.produit }}
          </router-link>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2 "
            @click="delRecord(item)"
            v-if="item.status_id == 'Brouillon'"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import DELETE_DEVIS from "../graphql/Devis/DELETE_DEVIS.gql";
//import downloadexcel from "vue-json-excel";
import ALLDEVIS from "../graphql/Devis/ALLDEVIS.gql";
import confirmdialog from "../components/ConfirmDialog.vue";
import graphqlClient from "../utils/graphql";

export default {
  components: {
    //downloadexcel,
    confirmdialog,
  },

  data: () => ({
    Loading: false,
    closeOnContentClick: false,
    search: null,
    parameters: [],
    headers: [
      {
        text: "Code",
        value: "nodevis",
        enum: "NODEVIS",
        selected: true,
      },
      {
        text: "Date devis",
        value: "datedevis",
        enum: "DATEDEVIS",
        selected: true,
      },
      {
        text: "Client",
        align: "start",
        value: "devisclients.name",
        enum: "CLIENT_ID",
        selected: true,
      },
      {
        text: "Produit",
        value: "produit",
        enum: "PRODUIT",
        selected: true,
      },
      {
        text: "Quantite",
        value: "qte",
        enum: "QTE",
        selected: true,
      },
      {
        text: "Support",
        value: "support.support",
        enum: "SUPPORT_ID",
        selected: true,
      },

      { text: "Laize", value: "laize", enum: "LAIZE", selected: true },
      {
        text: "Dev.",
        value: "dev",
        enum: "DEV",
        selected: true,
      },
      {
        text: "Status.",
        value: "status_id",
        enum: "STATUS_ID",
        selected: true,
      },
      { text: "Supprimer", value: "actions", sortable: false, selected: true },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    field: "NODEVIS",
    order: "DESC",
    devislist: [],
  }),

  computed: {
    selection() {
      return this.headers.filter((item) => {
        if (item.selected === true) {
          return item;
        }
      });
    },
    isDisable() {
      return this.$store.state.newdevis != "";
    },
  },
  watch: {},
  created() {},

  mounted() {
    this.Loading = true;
    graphqlClient
      .query({
        query: ALLDEVIS,

        variables: {
          field: "NODEVIS",
          order: "DESC",
        },
      })
      .then((response) => {
        this.devislist = response.data.alldevis;
        this.Loading = false;
      })
      .catch((error) => {
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar = true;
        this.Loading = false;
      });
    this.$store.dispatch("LoadData");
  },

  beforeRouteLeave(to, from, next) {
    let devis = Object.assign(
      {},
      this.devislist.find((dev) => dev.id == to.params.id)
    );
    this.$store.dispatch("LoadDevis", devis);
    next();
  },

  methods: {
    devisnew() {
      this.$store.dispatch("LoadDevis", []);
      this.$router.push({ name: "devis", params: { id: -1 } });
    },
    startDownload() {
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
    },
    finishDownload() {
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
      //this.snackbar = false;
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        this.deleteItemConfirm(item);
      }
    },
    deleteItemConfirm(item) {
      let i = this.devislist.findIndex((dev) => dev.id == item.id);
      graphqlClient
        .mutate({
          mutation: DELETE_DEVIS,
          variables: {
            id: item.id,
          },
        })
        .then(() => {
          this.snackbar_color = "success";
          this.snackbar_text = "Item deleted";
          this.snackbar = true;
          this.devislist.splice(i, 1);
        })
        .catch((error) => {
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: rgb(158, 184, 255) !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
